<template>
  <iq-main>
    <div class="container">
      <div class="wrapper">
        <div class="md-display-4">404</div>

        <div class="md-title">Ooops, something went wrong!</div>
        <div class="md-body-1">
          Looks like we couldn't find what you were looking for!
        </div>

        <md-button class="md-primary" @click="goBack">Go back</md-button>
      </div>
    </div>
  </iq-main>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  & > .wrapper {
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid var(--iq-gray-300);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;

    & > .md-title {
      font-weight: bold;
    }
  }
}
</style>
